import React, { useEffect, useState } from 'react';
import { withPrefix } from 'gatsby-link';
import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import './index.css';

function ScreenshotImagePopup({ label, url, urlList, closeFunc, selectedUrl }) {
  const [activeFile, setActiveFile] = useState(0);

  useEffect(() => {
    if (selectedUrl) {
      let _selectedUrlIndex = 0;

      urlList.map((_url, _urlIndex) => {
        if (_url === selectedUrl) {
          _selectedUrlIndex = _urlIndex;
        }
      });

      setActiveFile(_selectedUrlIndex);
    }
  }, [selectedUrl]);

  function nextFile(direction) {
    setActiveFile(activeFile + direction);
  }

  return (
    <div className="screenshot-image-popup">
      <div className="cover"></div>
      <div className="screenshot-image-popup-inner">
        <div className="title">
          <img
            src={withPrefix('/images/logo-icon.svg')}
            className="logo-icon"
          />
          {label}
          <IconButton
            style={{ float: 'right' }}
            aria-label="close"
            onClick={() => {
              closeFunc();
            }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="content">
          {(url ? [url] : urlList).map((curUrl, urlIndex) => (
            <img
              src={curUrl}
              alt={label}
              className={urlIndex === activeFile ? 'active' : ''}
            />
          ))}
          {urlList && urlList.length > 1 ? (
            <>
              {activeFile > 0 ? (
                <IconButton
                  className="nav left"
                  onClick={() => {
                    nextFile(-1);
                  }}>
                  <ChevronLeftIcon />
                </IconButton>
              ) : null}
              {activeFile < urlList.length - 1 ? (
                <IconButton
                  className="nav right"
                  onClick={() => {
                    nextFile(1);
                  }}>
                  <ChevronRightIcon />
                </IconButton>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ScreenshotImagePopup;
