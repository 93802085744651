import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import moment from 'moment';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Checkbox from '@mui/material/Checkbox';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputMask from 'react-input-mask';

import { ChevronRight } from '@mui/icons-material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import * as dataApi from '../../api/dataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import MarketingAgreement from './MarketingAgreement';
import ScreenshotImagePopup from '../ScreenshotImagePopup';
import Team from '../CompanyProfile/Team';

import './index.css';

function PartnerMultfamily({ siteConfig, webSocket, actions }) {
  const [showScreenshotPopup, setShowScreenshotPopup] = useState(null);
  const [contactRequest, setContactRequest] = useState({
    ['Date Signed']: moment().local().format('MM/DD/YYYY')
  });
  const [activeStep, setActiveStep] = useState(0);
  const [submitting, setSubmitting] = useState(null);

  function checkDisableSave() {
    if (submitting) {
      return true;
    }

    switch (activeStep) {
      case 0:
        return (
          !contactRequest['First Name'] ||
          !contactRequest['Last Name'] ||
          !contactRequest['Title'] ||
          !contactRequest['Email'] ||
          !contactRequest['Phone'] ||
          contactRequest['Phone'].indexOf('_') !== -1 ||
          !contactRequest['Property Name'] ||
          !contactRequest['Property Street Address'] ||
          !contactRequest['Property City'] ||
          !contactRequest['Property State'] ||
          !contactRequest['Property Zip'] ||
          !contactRequest['Number of Residential Units'] ||
          !contactRequest['Management Company']
        );
      case 1:
        return !contactRequest['Electronic Signature'];
      case 2:
        if (contactRequest['Where do we send your commission payments?']) {
          if (
            contactRequest['Where do we send your commission payments?'] ===
            'Other Address'
          ) {
            return (
              !contactRequest['Commission Street Address'] ||
              !contactRequest['Commission City, State Zip']
            );
          }

          return false;
        }

        return true;
      case 3:
        if (
          contactRequest[
            'How would you like to share your new resident data with us?'
          ]
        ) {
          if (
            contactRequest[
              'How would you like to share your new resident data with us?'
            ].indexOf('Direct') !== -1
          ) {
            return (
              !contactRequest['Integration Contact Name'] ||
              !contactRequest['Integration Contact Title'] ||
              !contactRequest['Integration Contact Email'] ||
              !contactRequest['Integration Contact Phone'] ||
              contactRequest['Integration Contact Phone'].indexOf('_') !== -1
            );
          }

          return false;
        }
        return true;
    }
  }

  return (
    <div className="page-container partner-multifamily">
      <div className="page hero">
        <div className="inner">
          <h1>$40 per sign-up</h1>
          <h2>
            That's what you'll earn when your new residents sign up for Texas
            electricity through the SmarterChoice website.
          </h2>
          <h2>
            <strong>Any plan - any brand</strong>
          </h2>

          <img
            src={withPrefix('/images/misc/electric-provider-list.png')}
            className="logos"
          />
          <img
            src={withPrefix('/images/misc/multifamily-trees.png')}
            className="trees"
          />
        </div>
      </div>
      <div className="page bottom">
        <div className="inner">
          <h4>Give your residents the POWER TO CHOOSE and help them</h4>
          <h3>
            <strong>Shop Smarter</strong> with{' '}
            <strong>SmarterChoice.com</strong>
          </h3>
        </div>
      </div>

      <div className="page bottom content orange">
        <div className="inner">
          <div
            className={'screen-container'}
            onClick={() => document.getElementById('enlargeDashboard').click()}>
            <div className="screenshot">
              <img
                src={withPrefix('/images/mi-landing/tablet-screenshot.svg')}
              />

              <div className="viewport">
                <img
                  src={withPrefix('/images/misc/multifamily-screenshot.png')}
                />
              </div>
            </div>

            <Button
              className="enlarge"
              onClick={() =>
                setShowScreenshotPopup({
                  url: withPrefix('/images/misc/multifamily-screenshot.png'),
                  label: 'SmarterChoice'
                })
              }
              id="enlargeDashboard">
              <ZoomInIcon />
              Enlarge Image
            </Button>
          </div>

          <div className="text-content">
            <h3>
              For you: <strong>Improved Revenue Sharing</strong>
            </h3>
            <p>
              Partner with SmarterChoice and you'll get paid $40 any time a
              resident sets up electric service through the SmarterChoice
              website.
            </p>
            <p>
              Not just on some plans and brands like traditional marketing
              agreements, but EVERY plan from EVERY brand.
            </p>

            <h3>
              For your residents: <strong>A Much Better Experience</strong>
            </h3>
            <p>
              Your residents know they have a choice when it comes to who they
              get their electricity from. Don't limit that choice by only
              offering a handful of plans. Promote the Power to Choose that they
              know they have by offering them dozens of plans and brands.
            </p>
            <p>
              <strong>And still get paid no matter who they choose.</strong>
            </p>
            <p>
              <a className="link" href="/demo2" target="_blank">
                Click here
              </a>{' '}
              to experience{' '}
              <a className="link" href="/demo2" target="_blank">
                SmarterChoice.com
              </a>{' '}
              for yourself.
            </p>
          </div>
        </div>
      </div>

      <div className="page bottom content blue">
        <div className="inner">
          <div className="text-content full">
            <h3>How it Works</h3>

            <ul>
              <li>
                <div className="step">
                  Step
                  <span>1</span>
                </div>
                <div className="text">
                  Register your property with SmarterChoice and join our revenue
                  sharing program using the form below.
                </div>
              </li>

              <li>
                <div className="step">
                  Step
                  <span>2</span>
                </div>
                <div className="text">
                  Choose how you want to share your new resident data with us.
                  Direct integration with your property management system is
                  available but not required.
                </div>
              </li>

              <li>
                <div className="step">
                  Step
                  <span>3</span>
                </div>
                <div className="text">
                  SmarterChoice then sends each new resident a personalized
                  email introducing them to the SmarterChoice.com website.
                </div>
              </li>

              <li>
                <div className="step">
                  Step
                  <span>4</span>
                </div>
                <div className="text">
                  When your new resident goes to SmarterChoice.com they are
                  presented with a wide range of electric plans and brands to
                  choose from.
                </div>
              </li>
              <li>
                <div className="step">
                  Step
                  <span>5</span>
                </div>
                <div className="text">
                  Each time one of your residents signs up for an electric plan
                  through the SmarterChoice website you get paid $40.00.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="page bottom">
        <div className="inner">
          <Team siteConfig={siteConfig} selectedMember={'bud'} singleMode />
        </div>
      </div>

      <div className="page bottom content green">
        <div className="inner">
          <div className="text-content full">
            <h3 className="left">I'm Ready - Sign Me Up</h3>
            <p>
              <strong>
                You can start the sign-up process by submitting the form below.
                Once we receive it, we’ll send you a confirmation email with a
                link to your property dashboard where you can confirm your data
                and complete the registration.
              </strong>
            </p>
            {/* <h3 className="right">
              <a
                className="link"
                onClick={() =>
                  dataApi.scrollToElementInPage(
                    document.getElementById('anchorTeam'),
                    -100
                  )
                }>
                I want to talk first
              </a>
            </h3> */}

            <div className="form">
              {/* <Stepper
                id="formStepper"
                alternativeLabel
                activeStep={activeStep}>
                <Step>
                  <StepLabel>Register Your Property</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Sign Marketing Agreement</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Commission Payment Address</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Start Sharing Resident Data</StepLabel>
                </Step>
              </Stepper> */}

              {activeStep > 0 ? (
                <>
                  <h4 style={{ marginTop: '30px' }}>Thank you!</h4>
                  <p>
                    Your account has been created and your registration is being
                    processed. We'll reach out to you if we need any additional
                    details. Once your registration is approved, we'll send you
                    an email with directions for accessing your account. The
                    approval process normally takes between 24-48 hours.
                  </p>
                </>
              ) : activeStep === 3 ? (
                <></>
              ) : activeStep === 2 ? (
                <></>
              ) : activeStep === 1 ? (
                <></>
              ) : (
                <>
                  <h4>Your Contact Information</h4>
                  <TextField
                    variant="outlined"
                    label="Your First Name"
                    required
                    fullWidth
                    value={contactRequest['First Name']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['First Name']: event.target.value
                      })
                    }
                  />

                  <TextField
                    variant="outlined"
                    label="Your Last Name"
                    required
                    fullWidth
                    value={contactRequest['Last Name']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Last Name']: event.target.value
                      })
                    }
                  />

                  <TextField
                    variant="outlined"
                    label="Title"
                    fullWidth
                    value={contactRequest['Title']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Title']: event.target.value
                      })
                    }
                    required
                  />

                  <TextField
                    variant="outlined"
                    label="Email"
                    required
                    fullWidth
                    value={contactRequest['Email']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Email']: event.target.value
                      })
                    }
                  />

                  <InputMask
                    mask="999-999-9999"
                    maskChar={'_'}
                    value={contactRequest['Phone']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Phone']: event.target.value
                      })
                    }>
                    {() => (
                      <TextField
                        fullWidth
                        label={'Phone'}
                        required
                        inputProps={{
                          inputMode: 'numeric'
                        }}
                      />
                    )}
                  </InputMask>

                  <h4>Property Details</h4>

                  <TextField
                    variant="outlined"
                    name="property-name"
                    label="Property Name"
                    fullWidth
                    value={contactRequest['Property Name']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Property Name']: event.target.value
                      })
                    }
                    required
                  />

                  <TextField
                    variant="outlined"
                    name="property-address"
                    label="Property Street Address"
                    fullWidth
                    value={contactRequest['Property Street Address']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Property Street Address']: event.target.value
                      })
                    }
                    required
                  />

                  <TextField
                    variant="outlined"
                    label="Property City"
                    name="property-city"
                    style={{ width: '60%' }}
                    value={contactRequest['Property City']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Property City']: event.target.value
                      })
                    }
                    required
                  />

                  <TextField
                    variant="outlined"
                    label="State"
                    name="property-state"
                    style={{ width: '18%', marginLeft: '2%' }}
                    value={contactRequest['Property State']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Property State']: event.target.value
                      })
                    }
                    required
                  />

                  <TextField
                    variant="outlined"
                    label="Zip"
                    name="property-zip"
                    style={{ width: '18%', marginLeft: '2%' }}
                    value={contactRequest['Property Zip']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Property Zip']: event.target.value
                      })
                    }
                    required
                  />

                  <TextField
                    variant="outlined"
                    name="number-of-units"
                    label="Number of Residential Units"
                    fullWidth
                    value={contactRequest['Number of Residential Units']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Number of Residential Units']: event.target.value
                      })
                    }
                    required
                  />

                  <TextField
                    variant="outlined"
                    name="management-company"
                    label="Management Company"
                    fullWidth
                    value={contactRequest['Management Company']}
                    onChange={(event) =>
                      setContactRequest({
                        ...contactRequest,
                        ['Management Company']: event.target.value
                      })
                    }
                    required
                  />
                </>
              )}

              {activeStep <= 1 ? (
                <div className="button">
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (activeStep === 0) {
                        setSubmitting(true);

                        dataApi
                          .saveInquiry(
                            'SmarterChoice Multifamily Partner Form',
                            contactRequest
                          )
                          .then((result) => {
                            setActiveStep(activeStep + 1);

                            dataApi.scrollToElementInPage(
                              document.getElementById('formStepper'),
                              -100,
                              true
                            );
                          });
                      } else {
                        setActiveStep(activeStep + 1);

                        dataApi.scrollToElementInPage(
                          document.getElementById('formStepper'),
                          -100,
                          true
                        );
                      }
                    }}
                    disabled={checkDisableSave()}>
                    {submitting ? 'Submitting...' : 'Submit Form'}
                    {/* {submitting
                      ? 'Submitting...'
                      : activeStep === 1
                      ? 'Accept and Continue'
                      : activeStep === 3
                      ? 'Save and Submit'
                      : 'Save and Continue'}{' '} */}
                    <ChevronRight />
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="page bottom team-secondary">
        <div className="inner">
          <Team
            team="sales"
            includeTitle
            siteConfig={siteConfig}
            selectedMember={'bud'}
          />
          <Team team="dev" siteConfig={siteConfig} />
        </div>
      </div>

      {showScreenshotPopup ? (
        <ScreenshotImagePopup
          {...showScreenshotPopup}
          closeFunc={() => setShowScreenshotPopup(null)}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerMultfamily);
